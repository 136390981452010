//@ts-nocheck
/* eslint-disable */
import axios from "axios";

import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/codes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaders } from "utils/getHeaders";
import { setCart, setLoading } from "store/Reducers/cartReducer";
import { load } from "@cashfreepayments/cashfree-js";


export const getCart = createAsyncThunk(
    "getCart",
    async ({ }: any, { dispatch }) => {
        try {
            dispatch(setLoading(true));
            const res = await axios.get(`${BASE_URL}/api/v1/cart`, getHeaders()
            );

            dispatch(setCart(res.data));

            dispatch(setLoading(false));
            return res;
        } catch (error: any) {
            dispatch(setLoading(false));
            // toast.error(error.response.data.message);
        }
    }
);




export const addItemToCart = createAsyncThunk(
    "addItemToCart",
    async ({ notifyAddTocart,image, name, ...data }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {

            const res = await axios.post(
                `${BASE_URL}/api/v1/cart/add`,
                {
                    ...data
                },
                getHeaders()
            );
            notifyAddTocart(name, data.quantity, data.price,image)
            dispatch(setLoading(false));
            dispatch(getCart({}));

            return res;
        } catch (error: any) {
            dispatch(setLoading(false));
            toast.error(error.response.data);
        }
    }
);



export const updateCartItem = createAsyncThunk(
    "updateCartItem",
    async ({ ...data }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const res = await axios.post(
                `${BASE_URL}/api/v1/cart/update`,
                {
                    ...data
                },
                getHeaders()
            );
            dispatch(setLoading(false));
            dispatch(getCart({}));

            return res;
        } catch (error: any) {
            dispatch(setLoading(false));
            toast.error(error.response.data);
        }
    }
);

// coupon code
export const applyCouponCode = createAsyncThunk(
    "applyCouponCode",
    async ({ reset, ...data }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {

            const res = await axios.post(
                `${BASE_URL}/api/v1/coupon/apply`,
                {
                    ...data
                },
                getHeaders()
            );
            await dispatch(getCart({}))
            reset()
            toast.success("coupon code successfully applied");

            dispatch(setLoading(false));

            return res.data;
        } catch (error: any) {
            dispatch(setLoading(false));
            toast.error(error.response.data?.errorMsg);
        }
    }
);

export const cancelCouponCode = createAsyncThunk(
    "cancelCouponCode",
    async ({ ...data }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {

            const res = await axios.post(
                `${BASE_URL}/api/v1/coupon/cancel`,
                {
                    ...data
                },
                getHeaders()
            );
            await dispatch(getCart({}))
            toast.success("coupon code successfully Canceled");

            dispatch(setLoading(false));

            return res.data;
        } catch (error: any) {
            dispatch(setLoading(false));
            toast.error(error.response.data?.errorMsg);
        }
    }
);


// cash Free payment
export const fetchCashFree = createAsyncThunk(
    "fetchCashFree",
    async ({ ...data }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {

            const res = await axios.post(
                `${BASE_URL}/api/v1/cashfree`,
                {
                    ...data
                },
                getHeaders()
            );

            const cashfree = await load({
                mode: "sandbox" //or production
            });

            let checkoutOptions = {
                paymentSessionId: res.data?.data?.payment_session_id,
                redirectTarget: "_self", //optional (_self or _blank)
            };

            cashfree.checkout(checkoutOptions);

            dispatch(setLoading(false));

            return res.data;
        } catch (error: any) {
            dispatch(setLoading(false));
            console.log(error.response);
            toast.error(error.response.data?.message);
        }
    }
);


// cash Free payment
export const verifyCashFree = createAsyncThunk(
    "verifyCashFree",
    async ({ navigate, ...data }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {

            const res = await axios.post(
                `${BASE_URL}/api/v1/cashfree/varify`,
                {
                    ...data
                },
                getHeaders()
            );


            dispatch(getCart({}))
            navigate("/account-my-order")
            dispatch(setLoading(false));

            return res.data;
        } catch (error: any) {
            console.log(error);
            dispatch(setLoading(false));
            toast.error(error.response.data?.message);
        }
    }
);


// razorpay  payment
export const fetchRazorPay = createAsyncThunk(
    "fetchRazorPay",
    async ({ navigate, ...data }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {

            const res = await axios.post(
                `${BASE_URL}/api/v1/razorpay`,
                {
                    ...data
                },
                getHeaders()
            );

            const { amount, id } = res.data?.data

            var options = {
                "key": process.env.REACT_APP_RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
                "amount": amount,
                "currency": "INR",
                "order_id": id,
                customer_id: data?.customer_details?.razorpayCustomerId,
                remember_customer:true,
                // "prefill":
                // {
                //     "email": data?.customer_details?.customer_email,
                //     "contact": data?.customer_details?.customer_phone,
                // },

                "handler": async function (response) {
                    await dispatch(verifyRazorpay({
                        order_id: response?.razorpay_order_id,
                        razorpay_payment_id: response?.razorpay_payment_id,
                        razorpay_signature: response?.razorpay_signature,
                        navigate
                    }))
                },
                "modal": {
                    "ondismiss": function () {
                        if (confirm("Are you sure, you want to close the form?")) {
                            txt = "You pressed OK!";
                            console.log("Checkout form closed by the user");
                        } else {
                            txt = "You pressed Cancel!";
                            console.log("Complete the Payment")
                        }
                    }
                }
            };
            var rzp1 = new Razorpay(options);
            rzp1.open();

            dispatch(setLoading(false));

            return res.data;
        } catch (error: any) {
            dispatch(setLoading(false));
            console.log(error);
            toast.error(error.response.data?.message);
        }
    }
);


// razorpay payment
export const verifyRazorpay = createAsyncThunk(
    "verifyRazorpay",
    async ({ navigate, ...data }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {

            const res = await axios.post(
                `${BASE_URL}/api/v1/razorpay/varify`,
                {
                    ...data
                },
                getHeaders()
            );


            dispatch(getCart({}))
            navigate("/account-my-order")
            dispatch(setLoading(false));

            return res.data;
        } catch (error: any) {
            console.log(error);
            dispatch(setLoading(false));
            toast.error(error.response.data?.message);
        }
    }
);