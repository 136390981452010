import React from "react";

const TermConditionPage = () => {
  return (
    <div className="container my-14 flex flex-col gap-5">
      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">Welcome to Day2Day Mart</h1>
        <p className="text-sm">
          This document is an electronic record in compliance with the
          Information Technology Act, 2000, and is published in accordance with
          the provisions of Rule 3 of the Information Technology (Intermediaries
          Guidelines) Rules, 2011. It outlines the rules, regulations, privacy
          policy, and Terms of Use governing access to or usage of the Day2Day
          Mart marketplace platform, available at www.day2daymart.com
          (hereinafter referred to as the "Platform").
        </p>
        <p className="text-sm">
          The Platform is owned and operated by Day2Day Mart, By accessing or
          using the Day2Day Mart Platform and its services, you ("User") agree
          to be bound by these Terms of Use ("Terms"), as well as the policies
          applicable to Day2Day Mart, all of which are incorporated by
          reference. Upon using the Platform, you are contracting with Day2Day
          Mart, and these Terms form a legally binding agreement between you and
          Day2Day Mart.
        </p>
        <p className="text-sm">
          For the purposes of these Terms, "You" or "User" refers to any
          individual or legal entity that registers as a buyer on the Platform
          by providing personal information. "Day2Day Mart", "We", "Us", or
          "Our" refers to Day2Day Mart and its affiliates.
        </p>

        <p className="text-sm">
          By using any of the services provided by us through the Platform
          (including but not limited to Product Reviews, Seller Reviews, etc.),
          you agree to comply with the applicable rules, guidelines, policies,
          and terms associated with those services. These are deemed to be part
          of and incorporated into these Terms of Use.
        </p>

        <p className="text-sm">
          We reserve the right, at our sole discretion, to modify, amend, or
          update these Terms at any time without prior notice to you. It is your
          responsibility to review these Terms periodically for any changes.
          Continued use of the Platform following any updates signifies your
          acceptance of the revised Terms. As long as you comply with these
          Terms, we grant you a personal, non-exclusive, non-transferable,
          limited privilege to access and use the Platform.
        </p>

        <p className="text-sm">
          By implicitly or explicitly accepting these Terms of Use, you also
          agree to be bound by the Day2Day Mart Policies, including the Privacy
          Policy, which may be amended from time to time
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">
          1. User Account, Password, and Security
        </h1>
        <p className="text-sm">
          By using the Platform, you are responsible for maintaining the
          confidentiality of your Display Name and Password. You agree that any
          activity carried out under your Display Name and Password will be
          considered your responsibility. If any information you provide is
          false, inaccurate, outdated, incomplete, or in violation of these
          Terms of Use, we reserve the right to suspend, terminate, or block
          your access to the Platform indefinitely.
        </p>
        <p className="text-sm">
          If we suspect a breach of security or misuse of your account, we may
          require you to change your password or, if necessary, temporarily
          suspend your account without liability to Day2Day Mart. We will not be
          held responsible for any loss or damage resulting from your failure to
          comply with these security requirements.
        </p>
        <p className="text-sm">
          In the event that a device is associated with multiple logins or
          sign-ups, as part of our commitment to enhancing customer security,
          privacy, and protecting sensitive information, we may monitor user
          activity and device associations while you access the Day2Day Mart
          website or app.
        </p>

        <p className="text-sm">
          Your mobile phone number serves as your primary identifier on the
          Platform. It is your responsibility to keep your mobile phone number
          and email address up to date at all times. If your mobile number or
          email changes, you must promptly update this information on the
          Platform through OTP verification. Day2Day Mart is not liable for any
          unauthorized activity or misuse that occurs under your account if you
          fail to update this information.
        </p>

        <p className="text-sm">
          Should you share your account with others, or allow them to create
          separate profiles under your account, they will have access to your
          account information. You are solely responsible for all activities
          conducted under your account and any consequences that may result from
          this shared access
        </p>

        <p className="text-sm">
          If your account remains inactive for a period of two (2) years, all
          data, including transactional data, will be deleted from our records,
          and you will no longer have access to this data.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">
          2. Platform for Transactions and Communication
        </h1>
        <p className="text-sm">
          The Platform serves as a medium for Users to meet and interact for
          transactions. Day2Day Mart does not and cannot become a party to, nor
          control, any transaction between Users on the Platform. As such:
        </p>
        <p className="text-sm">
          All commercial and contractual terms are solely agreed upon between
          Buyers and Sellers. These terms include, but are not limited to,
          pricing, shipping costs, payment methods, payment terms, delivery
          dates, delivery methods, warranties, and after-sales services. Day2Day
          Mart does not determine, advise, or involve itself in the negotiation
          or acceptance of such commercial terms between Buyers and Sellers.
        </p>
        <p className="text-sm">
          Day2Day Mart makes no representation or warranty regarding the
          specifics of products or services listed on the Platform, including
          but not limited to quality, value, or salability. The sale or purchase
          of any product or service is entirely between the Buyer and the
          Seller.
        </p>

        <p className="text-sm">
          Day2Day Mart is not responsible for any non-performance or breach of
          contract between Buyers and Sellers. We do not guarantee that Buyers
          or Sellers will fulfill their obligations in any transaction completed
          on the Platform.
        </p>

        <p className="text-sm">
          At no point does Day2Day Mart hold any ownership, title, or interest
          in the products being sold. We have no obligation or liability with
          respect to any contracts entered into between Buyers and Sellers.
        </p>

        <p className="text-sm">
          Day2Day Mart acts solely as a platform that facilitates communication
          between Buyers and Sellers, allowing them to reach a larger audience
          for the buying and selling of products or services. All sales
          contracts for products or services are strictly between the Buyer and
          Seller. Day2Day Mart is not responsible for any unsatisfactory
          performance, delayed services, damages, or delays caused by products
          being out of stock, unavailable, or back-ordered.
        </p>

        <p className="text-sm">
          Prices displayed on the Platform may occasionally be incorrect due to
          technical issues, typographical errors, or inaccuracies in product
          information provided by Sellers. In such cases, the Seller reserves
          the right to cancel the affected order(s).
        </p>

        <p className="text-sm">
          By using the Platform, you release and indemnify Day2Day Mart, its
          officers, and representatives from any costs, damages, liabilities, or
          consequences arising from the actions of other Users. While Day2Day
          Mart takes reasonable steps to ensure a secure and trustworthy
          Platform, we cannot guarantee the accuracy or control the information
          provided by other Users on the Platform
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">
          3. User Conduct and Rules on the Platform
        </h1>
        <p className="text-sm">
          By using the Platform, you agree, undertake, and confirm that your
          conduct will be strictly governed by the following principles:
        </p>

        <ul className="list-disc ml-4 flex flex-col gap-3">
          <li>
            <h1>
              You will not host, display, upload, modify, publish, transmit,
              update, or share any content that:
            </h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                Belongs to another person and to which you have no right.
              </li>
              <li className="text-sm">
                Is harmful, harassing, defamatory, obscene, pornographic,
                paedophilic, invasive of another's privacy, racially or
                ethnically objectionable, or otherwise unlawful.
              </li>
              <li className="text-sm">Misleads or misrepresents facts</li>
              <li className="text-sm">
                Promotes illegal activities or encourages abusive, threatening,
                or defamatory conduct.
              </li>
              <li className="text-sm">
                Violates third-party rights, including intellectual property
                rights, privacy, or publicity rights..
              </li>
              <li className="text-sm">
                Contains hidden pages, restricted access areas, or unauthorized
                links.
              </li>
              <li className="text-sm">
                Provides information about illegal activities or violates any
                applicable law..
              </li>
              <li className="text-sm">
                ○ Contains images or videos of individuals without their
                consent.
              </li>
              <li className="text-sm">
                Attempts to access restricted areas of the Platform, including
                profiles, blogs, or private information, without authorization..
              </li>
              <li className="text-sm">
                Interferes with another User’s experience on the Platform.
              </li>
              <li className="text-sm">
                Involves fraud, the sale of counterfeit or stolen goods, or
                violates patent, trademark, or copyright laws..
              </li>
            </ul>
          </li>
          <li>
            <h1>You agree not to use the Platform to:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                Engage in any illegal or fraudulent activities.
              </li>
              <li className="text-sm">
                Mislead or impersonate any individual or entity
              </li>
              <li className="text-sm">
                Use false information, such as fake addresses, emails, or phone
                numbers.
              </li>
              <li className="text-sm">
                Send unsolicited communications like junk mail or spam
              </li>
              <li className="text-sm">
                Engage in business-to-business transactions aimed at availing
                benefits like tax credits.
              </li>
            </ul>
          </li>
          <li>
            <h1>Prohibited Actions and Consequences:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                Activities such as misuse of vouchers, submitting fraudulent
                documents, making excessive returns, or using fake information
                may result in the suspension or termination of your account
              </li>
              <li className="text-sm">
                Bulk orders made for commercial resale, placed using invalid
                addresses, or through malpractice will be canceled. Vouchers or
                promotional codes used in such orders may not be refunded.
              </li>
            </ul>
          </li>
          <li>
            <h1>User-Generated Content:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                We allow users to share opinions, post content, and participate
                in discussions on specific pages. By doing so, you agree to
                comply with these Terms of Use and our Privacy Policies. You
                also agree not to harass or abuse other users when engaging in
                these activities.
              </li>
            </ul>
          </li>
          <li>
            <h1>Unauthorized Access:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                You will not use automated tools such as "deep-links," "robots,"
                or "spiders" to access, copy, or monitor any part of the
                Platform.
              </li>
              <li className="text-sm">
                Attempts to hack or gain unauthorized access to the Platform or
                any connected systems are prohibited.
              </li>
            </ul>
          </li>
          <li>
            <h1>Compliance with Laws:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                You must comply with applicable laws, including the Information
                Technology Act, 2000, and other relevant domestic and
                international laws. You will not engage in transactions that are
                prohibited by law
              </li>
            </ul>
          </li>
          <li>
            <h1>Accuracy of Information:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                You are responsible for providing accurate information about any
                products or services you list or sell on the Platform.
                Misrepresentation or exaggeration of product attributes to
                mislead other users is strictly prohibited
              </li>
            </ul>
          </li>
          <li>
            <h1>Advertising and Solicitation:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                You are not allowed to solicit other users to buy or sell
                products or services that are unrelated to what is displayed on
                the Platform.
              </li>
            </ul>
          </li>
          <li>
            <h1> Content Responsibility:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                Content posted by users does not necessarily reflect Day2Day
                Mart's views. We are not responsible for any claims, damages, or
                losses resulting from user-generated content. You represent and
                warrant that you have all necessary rights to the content you
                provide.
              </li>
            </ul>
          </li>
          <li>
            <h1>AI Features:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                Any AI-generated tags, reviews, or suggestions on the Platform
                are powered by third-party services and may not always be
                accurate. Please exercise discretion when interacting with
                AI-generated content.
              </li>
            </ul>
          </li>
          <li>
            <h1>Product Representation:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                Day2Day Mart does not guarantee the exactness of a product’s
                appearance, size, or color as displayed on the Platform. Our
                Return and Exchange Policy governs the conditions under which
                products may be returned or exchanged, as outlined on the
                product detail page.
              </li>
            </ul>
          </li>
          <li>
            <h1>Day2Day Mart Credits Usage:</h1>
            <ul className="list-decimal ml-3">
              <li className="text-sm">
                Any Day2Day Mart credits can only be used for purchases made
                through your registered account on the Day2Day Mart platform.
                They cannot be transferred to other users, bank accounts, or
                wallets.
              </li>
            </ul>
          </li>
        </ul>
        <p className="text-sm">
          Day2Day Mart reserves the right to terminate your account for
          violations of these terms and conditions. Any credits earned through
          goodwill compensation, loyalty programs, or promotions will be
          forfeited in case of account termination.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">
          4. Content Posted on the Platform
        </h1>
        <p className="text-sm">
          All content, including text, graphics, user interfaces, visual
          interfaces, photographs, trademarks, logos, sounds, music, and artwork
          (collectively referred to as "Content"), is primarily third-party
          user-generated content. Day2Day Mart does not control or manage this
          third-party content and acts merely as an intermediary, as outlined in
          these Terms of Use. By submitting content to the Platform, you agree
          that such content becomes our property, and you grant us worldwide,
          perpetual, and transferable rights to use, modify, and distribute the
          content. This includes using it for promotional and advertising
          purposes in any media format now known or later developed, as well as
          creating derivative works based on the content, in accordance with our
          Privacy Policy and applicable laws.
        </p>
        <p className="text-sm">
          You may download and use information made available on the Platform
          regarding products and services, provided that:
        </p>
        <ul className="list-decimal ml-3">
          <li className="text-sm">
            You do not remove any proprietary notices from such documents.
          </li>
          <li className="text-sm">
            You do not remove any proprietary notices from such documents.
          </li>

          <li className="text-sm">No modifications are made to the content.</li>

          <li className="text-sm">
            You do not make any additional representations or warranties
            regarding the information.
          </li>
        </ul>

        <h1 className="text-base font-bold">
          Return/Cancellation and Offer Eligibility
        </h1>
        <p className="text-sm">
          In the case of a product return or cancellation that alters the
          eligibility of an offer (such as falling below the minimum required
          threshold), you will no longer qualify for that offer. Any cashback or
          credits already issued will be voided and deducted from the value of
          the returned/canceled product, and the remaining balance will be
          processed as a refund.
        </p>
        <p className="text-sm">
          Day2Day Mart reserves the right to modify these terms and conditions
          at any time without prior notice
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">
          5. Disclaimer of Warranties and Liability
        </h1>
        <p className="text-sm">
          All materials, products (including but not limited to software), and
          services made available to you through the Day2Day Mart platform are
          provided on an "as is" and "as available" basis, without any
          representation or warranties, whether express or implied, unless
          explicitly specified in writing. Without limiting the scope of the
          preceding statement, Day2Day Mart does not guarantee that:
        </p>
        <ul className="list-decimal ml-3">
          <li className="text-sm">
            The platform will be available consistently or without
            interruptions.
          </li>
          <li className="text-sm">
            The information provided on the platform is complete, accurate,
            true, or non-misleading.
          </li>

          <li className="text-sm">No modifications are made to the content.</li>
        </ul>
        <p className="text-sm">
          All products sold on the platform are subject to applicable state
          laws. If a seller is unable to deliver a product due to legal
          restrictions in a particular state, the seller will either return the
          amount received in advance for the undelivered product or offer a
          credit for the same.
        </p>
        <p className="text-sm">
          You are required to provide a valid phone number while placing an
          order. By registering your phone number with Day2Day Mart, you agree
          to be contacted via phone calls and/or SMS for updates related to your
          order, shipment, or delivery. Your personal information will not be
          used for any promotional phone calls or SMS notifications unless you
          provide explicit consent for such communications.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">
          6. Payment Methods and Liability
        </h1>
        <p className="text-sm">
          When using any of the payment methods available on the Day2Day Mart
          platform, we do not assume responsibility or liability for any loss or
          damage resulting from
        </p>
        <ul className="list-decimal ml-3">
          <li className="text-sm">Lack of authorization for a transaction.</li>
          <li className="text-sm">
            Exceeding any limit set between you and your bank.
          </li>
          <li className="text-sm">
            Decline of the transaction for any other reason.
          </li>

          <li className="text-sm">
            Payment issues arising during the transaction.
          </li>
        </ul>
        <p className="text-sm">
          All payments for purchases on the platform must be made in Indian
          Rupees, as accepted within the Republic of India. Transactions in any
          other currency are not facilitated.
        </p>
        <p className="text-sm">
          Before shipping or delivering your order, sellers may request
          additional documentation, such as government-issued ID and address
          proof, to verify ownership of the payment method used. This ensures a
          secure shopping experience for all users.
        </p>

        <h1 className="text-base font-bold">
          Commercial Terms and Transaction Facility
        </h1>
        <p className="text-sm">
          Transactions, transaction price, and other commercial terms such as
          delivery and dispatch are agreements between the buyer and seller.
          Day2Day Mart’s payment facility is merely a service to facilitate
          these transactions and does not hold liability for issues like
          non-delivery, non-receipt, non-payment, damage, breach of warranty, or
          fraud related to products and services listed on the platform.
        </p>

        <p className="text-sm">
          You authorize Day2Day Mart or its service providers to process
          payments electronically or through cash on delivery for transactions
          conducted on the platform. Day2Day Mart acts as an independent
          contractor, with no control over the products or services listed on
          the platform or the outcome of transactions. We do not guarantee the
          identity of any user, nor do we ensure that buyers or sellers will
          complete a transaction.
        </p>

        <p className="text-sm">
          The payment facility provided by Day2Day Mart is not a banking or
          financial service, but rather a facilitator using authorized banking
          infrastructure and payment gateways. By using the payment facility,
          Day2Day Mart is not acting as a trustee or in a fiduciary capacity
          with respect to the transaction or transaction price.
        </p>

        <h1 className="text-base font-bold">Buyer's Use of Payment Facility</h1>
        <p className="text-sm">
          As a buyer, when initiating a transaction, you enter into a legally
          binding agreement with the seller to purchase the product or service
          using the payment facility. You may communicate with the seller
          regarding extensions to delivery timelines or other transaction
          modifications, which should comply with the platform’s rules and
          policies.
        </p>

        <p className="text-sm">
          For cash-on-delivery transactions, no confirmation is required from
          the buyer to acknowledge receipt of the product. However, if the
          delivery does not occur within the agreed time, you may be entitled to
          a refund, provided you raise the claim within the stipulated time as
          per platform policies.
        </p>

        <p className="text-sm">
          Refunds for electronic payments will be processed through the original
          payment method, and for cash-on-delivery transactions, refunds will be
          made via electronic transfer. All refunds will be in Indian Rupees,
          equivalent to the original transaction amount.
        </p>

        <p className="text-sm">
          Day2Day Mart reserves the right to impose limits on the number of
          transactions or the transaction amount for individual payment methods
          and may decline transactions for security reasons, suspicious
          activity, or previous questionable charges. Day2Day Mart may also
          delay payment confirmations for high-volume transactions or if deemed
          necessary for safety reasons.
        </p>

        <h1 className="text-base font-bold">Compliance with Laws</h1>
        <p className="text-sm">
          For purchases exceeding INR 2,00,000, buyers are required to upload a
          scanned copy of their PAN card within 4 days of the transaction.
          Failure to do so will result in order cancellation. This is a one-time
          requirement for customers who haven’t previously submitted their PAN
          details.
        </p>

        <p className="text-sm">
          Both buyers and sellers must comply with all applicable laws,
          including but not limited to the Foreign Exchange Management Act,
          Information Technology Act, Prevention of Money Laundering Act, and
          Income Tax Act when using the payment facility.
        </p>

        <h1 className="text-base font-bold">
          Buyer's Agreement with Issuing Bank
        </h1>
        <p className="text-sm">
          All credit/debit card and other payment methods are processed using
          secure payment gateways, subject to the terms agreed between the buyer
          and the respective issuing bank. Online bank transfers are similarly
          processed through authorized banking gateways, and these transactions
          are governed by the terms between the buyer and their bank
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">
          7. E-Platform for Communication
        </h1>
        <p className="text-sm">
          You acknowledge and agree that Day2Day Mart is an online marketplace
          that enables you to browse and purchase products listed by sellers at
          the displayed prices at any given time. You further understand that
          Day2Day Mart serves solely as a facilitator and is not a party to or
          has control over any transactions conducted on the platform between
          buyers and sellers
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">8. Indemnity</h1>
        <p className="text-sm">
          You agree to indemnify and hold harmless Day2Day Mart, its owners,
          licensees, affiliates, subsidiaries, group companies, and their
          respective officers, directors, agents, and employees from any claims,
          demands, actions, or penalties, including reasonable attorney fees,
          arising from your breach of these Terms of Use, the Privacy Policy,
          other platform policies, or any violation of laws or third-party
          rights (including intellectual property rights) as a result of your
          actions
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">
          9. Trademarks, Copyrights, and Restrictions
        </h1>
        <p className="text-sm">
          Day2Day Mart is controlled and operated by us, and the products sold
          are offered by respective sellers. All content on the platform,
          including images, illustrations, audio clips, and videos, is protected
          by copyrights, trademarks, and other intellectual property rights. You
          may only use this material for personal, non-commercial purposes.
          Reproduction, republishing, uploading, posting, transmitting, or
          distributing the material in any form, including electronically, is
          prohibited unless prior written permission from the owner is obtained.
        </p>

        <p className="text-sm">
          Any commercial use of the material, including modifying, copying, or
          distributing it for profit, is strictly forbidden unless expressly
          permitted. You retain ownership of any content you upload or provide
          when using the platform. However, by uploading or providing content,
          you grant us an irrevocable, royalty-free, perpetual, and worldwide
          license to use, reproduce, or modify such content for business
          purposes.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">10. Limitation of Liability</h1>
        <p className="text-sm">
          Day2Day Mart shall not be liable for any indirect, punitive,
          incidental, special, consequential, or any other damages resulting
          from:
        </p>
        <ul className="list-decimal ml-3">
          <li className="text-sm">
            The use or inability to use our services or products
          </li>
          <li className="text-sm">
            Unauthorized access to or alteration of your data or transmissions.
          </li>
          <li className="text-sm">
            Breach of warranties or representations made by the product
            manufacturers.
          </li>

          <li className="text-sm">
            Any other issues related to the platform or its services, including
            but not limited to, loss of data, profits, or use.
          </li>
        </ul>

        <p className="text-sm">
          Day2Day Mart is not responsible for the unavailability of the platform
          during scheduled maintenance or unexpected service suspensions. You
          acknowledge that any material or data downloaded from the platform is
          done at your discretion and risk, and Day2Day Mart is not liable for
          any damage to your device or loss of data resulting from such
          downloads.
        </p>
        <p className="text-sm">
          To the fullest extent permitted by law, Day2Day Mart’s liability will
          be limited to the purchase value of the product(s) in question.
          Day2Day Mart shall not be responsible for resolving disputes or
          disagreements between users of the platform.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">11. Termination</h1>
        <p className="text-sm">
          Day2Day Mart reserves the right to suspend or terminate your account
          or access to any of its services at its sole discretion if it believes
          you have violated, abused, or manipulated any of the terms outlined in
          these Terms of Service, or if you have acted unethically in any
          manner. These Terms of Service will remain in effect indefinitely
          unless and until Day2Day Mart decides to terminate them
        </p>

        <p className="text-sm">
          Upon termination by either you or Day2Day Mart, we may delete any
          content or materials related to your use of the platform or services,
          without any liability to you or any third party. However, transaction
          details may be retained for purposes of tax or regulatory compliance.
        </p>
        <p className="text-sm">
          Day2Day Mart also reserves the right to unilaterally terminate your
          account under the circumstances outlined in the User Conduct and Rules
          section. Any credits or rewards, whether earned through loyalty
          programs, referral schemes, promotional campaigns, or gift cards, will
          be forfeited in such cases. Refunds or returns for these users will be
          at the sole discretion of Day2Day Mart.
        </p>
        <p className="text-sm">
          If you use false information, engage in fraudulent activities, or
          misuse the platform in any manner, such as providing multiple false
          addresses or phone numbers or transacting with dishonest intentions,
          Day2Day Mart reserves the right to refuse access, terminate your
          account, and any linked accounts without prior notice.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">
          12. Jurisdictional Issues/Sale in India Only
        </h1>
        <p className="text-sm">
          Unless otherwise specified, the materials on the Platform are
          presented solely for the purpose of sale in India. Day2Day Mart makes
          no representations regarding the appropriateness or availability of
          materials for use in locations or countries outside India. Users
          accessing the Platform from locations or countries outside India do so
          at their own risk, and Day2Day Mart is not responsible for the supply
          or refund of products ordered from outside India, nor for compliance
          with local laws that may apply.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">13. Governing Law</h1>
        <p className="text-sm">
          These terms shall be governed by and construed in accordance with the
          laws of India, without regard to conflict of laws principles. Any
          disputes arising in relation to these terms shall be subject to the
          exclusive jurisdiction of the courts, tribunals, and authorities
          located in Bangalore, India.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">14. Contacting the Seller</h1>
        <p className="text-sm">
          At Day2Day Mart, we are dedicated to ensuring that disputes between
          sellers and buyers are resolved amicably through established
          mechanisms. If you wish to contact Day2Day Mart regarding a seller,
          you can do so by clicking on the seller's name on the product listing
          pages. Alternatively, you may reach out to our customer support at
          094-28957772 or email us on{" "}
          <a href="contact@day2daymart.com">contact@day2daymart.com</a>
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">15. Disclaime</h1>
        <p className="text-sm">
          You acknowledge and agree that you are accessing the services on the
          Platform and engaging in transactions at your own risk, exercising
          your best judgment before proceeding with any purchases. Day2Day Mart
          shall not be liable for any actions or inactions of sellers, nor for
          any breaches of conditions, representations, or warranties by the
          sellers or manufacturers of the products. We expressly disclaim all
          responsibility and liability in this regard. Additionally, we will not
          mediate or resolve any disputes or disagreements between you and the
          sellers or manufacturers of the products.
        </p>
        <p className="text-sm">
          We further disclaim any warranties or representations (express or
          implied) regarding the quality, suitability, accuracy, reliability,
          completeness, timeliness, performance, safety, merchantability,
          fitness for a particular purpose, or legality of the products listed,
          displayed, or transacted on the Platform. While we have taken
          precautions to prevent inaccuracies in content, all content,
          information (including product pricing), software, products, services,
          and related graphics on this website are provided "as is," without any
          warranty of any kind. At no time shall any right, title, or interest
          in the products sold through or displayed on the Platform vest with
          Day2Day Mart, nor shall Day2Day Mart have any obligations or
          liabilities concerning any transactions on the Platform.
        </p>
        <h1 className="text-base font-bold">Delivery-Related Claims</h1>

        <p className="text-sm">
          You agree that any claims regarding order delivery (including
          non-receipt or non-delivery of an order or signature verification)
          must be notified to Day2Day Mart within 5 days of the alleged delivery
          date reflected on the Platform. Failure to notify us of non-receipt or
          non-delivery within the specified time frame will be considered as a
          deemed delivery for that transaction. Day2Day Mart disclaims any
          liability or responsibility for claims regarding non-delivery or
          non-receipt of orders (including signature verification) after the
          5-day period from the alleged delivery date.
        </p>

        <h1 className="text-base font-bold">Open Box Delivery</h1>
        <p className="text-sm">
          Open Box Delivery is currently available in select PIN codes only. For
          Cash on Delivery (COD) orders, payment for the product must be made
          before opening the box. During Open Box Delivery, our delivery partner
          will open the package in your presence at the time of delivery. Both
          the outer and brand packaging will be opened and shown to you,
          allowing you to thoroughly check the product before accepting
          delivery. If you find any part of the product to be missing, damaged,
          or different from what you ordered, please refuse the order and
          instruct our delivery partner to take it back. For COD orders, you
          will receive an immediate on-the-spot refund from our delivery
          partner. For prepaid orders, the refund will be credited back to the
          original payment source. If you find an electronic device to be
          defective upon turning it on, please contact our customer care for
          assistance.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">16. Cart Notification</h1>
        <p className="text-sm">
          You acknowledge that if the selected products in your cart are out of
          stock, Day2Day Mart offers you the option to choose a product from
          another seller. You understand that the price of the product from the
          alternative seller may vary.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">17. Multiple Sellers</h1>
        <p className="text-sm">
          You recognize that products of a particular style may be available
          from multiple sellers on the Platform, and the product price displayed
          on the listing page may not always represent the lowest price for that
          specific style. This is because the seller whose price is shown is
          selected based on various parameters, of which price is only one
          factor. However, once you navigate to the product display page for a
          specific style, you will have access to the prices offered by all
          sellers on the Platform for that style.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">18. Charges</h1>
        <p className="text-sm">
          Subject to the Terms of Use and the conditions outlined below, Day2Day
          Mart may impose a charge on all low-value orders placed on the
          Platform, as detailed in our FAQ section
          (https://www.day2daymart.com/faqs) and under point 5 below.
        </p>
        <ul className="list-disc ml-3">
          <li className="text-sm">
            The fee will be based on the overall order value
          </li>
          <li className="text-sm">
            The fee will be specified on the shopping bag page of the Platform.
          </li>
          <li className="text-sm">
            Day2Day Mart reserves the right to modify or waive this fee at any
            time
          </li>

          <li className="text-sm">
            The fee will be quoted in Indian Rupees. You are solely responsible
            for complying with all applicable laws regarding payments to Day2Day
            Mart
          </li>
        </ul>

        <p className="text-sm">
          To safeguard the rights of our customers, Day2Day Mart reserves the
          right to collect this fee for all orders and disable the
          cash-on-delivery option for accounts that exhibit a high percentage of
          returns or unaccepted shipments relative to the number of orders
          placed through such accounts.
        </p>
        <p className="text-sm">
          To the fullest extent permitted by law, Day2Day Mart’s liability will
          be limited to the purchase value of the product(s) in question.
          Day2Day Mart shall not be responsible for resolving disputes or
          disagreements between users of the platform.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold mb-3">19. Font Copyright</h1>
        <p className="text-sm">
          Copyright © (2023-Present), <a href="#" className="text-blue-500">SIL Open Font License</a>.
        </p>
      </div>
    </div>
  );
};

export default TermConditionPage;
