export const calculateAverageRating = (users: any) => {
    // Check if users array is not empty
    if (users.length === 0) {
        return 0; // Return 0 if no ratings are provided
    }

    // Calculate the sum of ratings
    const sum = users.reduce((acc: any, user: any) => acc + user.rating, 0);

    // Calculate the average rating
    const averageRating = sum / users.length;

    // Round the average rating to two decimal places
    return Math.round(averageRating * 100) / 100;
}


export const sortReview = (review: any, flag: any) => {
    let sortedAsc
    if (flag == "Lowest rating") {
        sortedAsc = review.slice().sort((a: any, b: any) => a.rating - b.rating);
    }

    if (flag == "Highest rating") {
        sortedAsc = review.slice().sort((a: any, b: any) => b.rating - a.rating);

    }

    return sortedAsc

}

// cart
// get update cart api input 
export const getUpdateCartInput = (items: any, updatedQty: number, id: string) => {
    const newItems = items.map((item: any) => {
        const {
            product: { _id },
            quantity,
            price,
        } = item;

        if (item._id == id) {
            return {
                product: _id,
                quantity: updatedQty,
                price,
            };
        }

        return {
            product: _id,
            quantity,
            price,
        };
    });


    return {
        items: newItems
    }
}



// cart
// get removecart api input 
export const getRemoveCartInput = (items: any, id: string) => {
    const newItems = items.filter((item: any) => {
        const {
            product: { _id },
            quantity,
            price,
        } = item;

        if (item._id != id) {
            return {
                product: _id,
                quantity,
                price,
            };
        }
    });


    return {
        items: newItems
    }
}
