// @ts-nocheck
/* eslint-disable */

import React from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import Heading from "components/Heading/Heading";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import { useSelector } from "react-redux";
import useNavlist from "talons/useNavlist";
import ProductSection from "./DynamicCategorySection";
import Ad from "components/ADD/ad";
const products = [
  {
    price: 9,
    image: "https://example.com/product1.jpg",
    shopUrl: "https://example.com/shop1",
    name: "Peeler",
  },
  {
    price: 19,
    image: "https://example.com/product2.jpg",
    shopUrl: "https://example.com/shop2",
    name: "Phone Stand",
  },
  {
    price: 29,
    image: "https://example.com/product3.jpg",
    shopUrl: "https://example.com/shop3",
    name: "Hanger Set",
  },
  {
    price: 49,
    image: "https://example.com/product4.jpg",
    shopUrl: "https://example.com/shop4",
    name: "Key Light",
  },
];

function PageHome() {
  const newArrivalProducts = useSelector(
    (state: any) => state.productReducer.newArrivalProducts
  );

  const trendingProducts = useSelector(
    (state: any) => state.productReducer.trendingProducts
  );

  const bestSellerProducts = useSelector(
    (state: any) => state.productReducer.bestSellerProducts
  );

  const { categoryItems, dynamicCategory } = useNavlist();

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* SECTION HERO */}
      <Ad/>
      <SectionHero2 />

      {/* <div className="mt-24 lg:mt-32">
        <DiscoverMoreSlider />
      </div> */}

      <div className="relative space-y-10 my-10 lg:space-y-10 lg:my-10">
        <ProductSection categories={dynamicCategory} />
      </div>

      <div className="container relative space-y-10 my-10 lg:space-y-10 lg:my-10">
        {dynamicCategory?.length
          ? dynamicCategory?.map((item: any) => {
              return (
                <SectionSliderProductCard
                  heading={item?.name}
                  subHeading=""
                  data={item?.products}
                />
              );
            })
          : null}

        {/* SECTION */}
        {newArrivalProducts?.length && (
          <SectionSliderProductCard data={newArrivalProducts} />
        )}

        {/* <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
          <SectionHowItWork />
        </div> */}

        {/* SECTION */}
        <SectionPromo1 />

        {/* SECTION */}
        {/* <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <SectionGridMoreExplore />
        </div> */}

        {/* SECTION */}
        {trendingProducts?.length && (
          <SectionGridFeatureItems data={trendingProducts} />
        )}

        {/*  */}
        <SectionPromo2 />

        {/* SECTION 3 */}
        {/* <SectionSliderLargeProduct cardStyle="style2" /> */}

        {/*  */}
        {/* <SectionSliderCategories /> */}

        {/* SECTION */}
        <SectionPromo3 />
        {bestSellerProducts?.length && (
          <SectionSliderProductCard
            heading="Best Sellers"
            subHeading="Best selling of the month"
            data={bestSellerProducts}
          />
        )}

        {/* <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <div>
            <Heading rightDescText="From the Ciseco blog">
              The latest news
            </Heading>
            <SectionMagazine5 />
            <div className="flex mt-16 justify-center">
              <ButtonSecondary>Show all blog articles</ButtonSecondary>
            </div>
          </div>
        </div> */}

        {/*  */}
        {/* <SectionClientSay /> */}
      </div>
    </div>
  );
}

export default PageHome;
