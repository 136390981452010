import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchCategoryAction } from "store/Actions/CategoryAction";
import { fetchCustomerbyToken } from "store/Actions/CustomerAction";
import { fetchBestSellerProducts, fetchNewArrivalProducts, fetchProductsAction, fetchTrendingProducts } from "store/Actions/ProductAction";
import { getWishlist } from "store/Actions/WishlistAction";
import { getCart } from "store/Actions/cartAction";

function useInit() {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(fetchCategoryAction({}));
    dispatch(fetchNewArrivalProducts({}))
    dispatch(fetchTrendingProducts({}))
    dispatch(fetchBestSellerProducts({}))
    dispatch(getCart({}))
    dispatch(getWishlist({}))
    dispatch(fetchCustomerbyToken({}))

  }, []);
  

  return {};
}

export default useInit;
