import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/codes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaders } from "utils/getHeaders";
import { setwishlistItems, setLoading } from "store/Reducers/WishlistReducer";


export const getWishlist = createAsyncThunk(
    "getWishlist",
    async ({ }: any, { dispatch }) => {
        try {
            dispatch(setLoading(true));
            const res = await axios.get(`${BASE_URL}/api/v1/wishlist-item`, getHeaders()
            );

            dispatch(setwishlistItems(res.data.data.data));

            dispatch(setLoading(false));
            return res;
        } catch (error: any) {
            dispatch(setLoading(false));
            // toast.error(error.response.data.message);
        }
    }
);




export const addItemToWishlist = createAsyncThunk(
    "addItemToWishlist",
    async ({ ...data }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {

            const res = await axios.post(
                `${BASE_URL}/api/v1/wishlist-item`,
                {
                    ...data
                },
                getHeaders()
            );
            dispatch(setLoading(false));
            dispatch(getWishlist({}));

            return res;
        } catch (error: any) {
            dispatch(setLoading(false));
            toast.error(error.response.data);
        }
    }
);



export const deleteWishlistItem = createAsyncThunk(
    "deleteWishlistItem",
    async ({ id }: any, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const res = await axios.delete(
                `${BASE_URL}/api/v1/wishlist-item/${id}`,
                getHeaders()
            );
            dispatch(setLoading(false));
            dispatch(getWishlist({}));

            return res;
        } catch (error: any) {
            dispatch(setLoading(false));
            toast.error(error.response.data);
        }
    }
);