import React, { useEffect } from 'react';

const Ad = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        script.async = true;
        document.body.appendChild(script);

        window.adsbygoogle = window.adsbygoogle || [];
    }, []);

    return (
        <ins className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-4475011170632872"
            data-ad-slot="4523405881"
            data-ad-format="auto"
            data-full-width-responsive="true"/>
        
    );
};

export default Ad;
