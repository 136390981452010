import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./ProtectedRoute";
import AccountShipping from "containers/AccountPage/AccountShipping";
import VerifyPayment from "containers/VerifyPayment";
import TermConditionPage from "containers/TermConditionPage/TermConditionPage";
import ForgtoPasswordPage from "containers/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "containers/ResetPasswordPage/ResetPasswordPage";
import PrivacyPolicy from "containers/PolicyPage/PrivacyPolicy";
import TermsCondition from "containers/PolicyPage/TermsCondition";

export const pages: Page[] = [
  { path: "/", component: PageHome },
  // { path: "/home2", component: PageHome2 },
  // { path: "/home3", component: PageHome3 },
  //
  { path: "/home-header-2", component: PageHome },
  { path: "/product-detail/:id", component: ProductDetailPage },
  // { path: "/product-detail-2", component: ProductDetailPage2 },
  //
  { path: "/page-collection-2/:id", component: PageCollection2 },
  // { path: "/page-collection", component: PageCollection },
  { path: "/page-search/", component: PageSearch },
  //
  { path: "/account", component: AccountPage, isPrivate: true },
  { path: "/varifyPayment", component: VerifyPayment, isPrivate: true },
  { path: "/account-savelists", component: AccountSavelists, isPrivate: true },
  { path: "/account-change-password", component: AccountPass, isPrivate: true },
  { path: "/account-shipping", component: AccountShipping, isPrivate: true },
  { path: "/account-my-order", component: AccountOrder, isPrivate: true },
  //
  { path: "/cart", component: CartPage, isPrivate: true },
  { path: "/checkout", component: CheckoutPage, isPrivate: true },
  { path: "/term-and-condition", component: TermConditionPage },
  // { path: "/blog", component: BlogPage },
  // { path: "/blog-single", component: BlogSingle },

  { path: "/privacy-policy", component: PrivacyPolicy},
  { path: "/terms-condition", component: TermsCondition},
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/forgot-pass", component: ForgtoPasswordPage },
  { path: "/reset-password/:token", component: ResetPasswordPage },
  { path: "/login", component: PageLogin },
  // { path: "/subscription", component: PageSubcription },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ToastContainer />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path, isPrivate }, index) => {
          if (isPrivate) {
            return (
              <Route key={index} element={<ProtectedRoute />}>
                <Route element={<Component />} path={path} />
              </Route>
            );
          }

          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
