import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    cart:{}
};

export const cartSlice = createSlice({
    name: "cartSlice",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setCart: (state, action) => {
            state.cart = action.payload;
        },

    },
});

// Action creators are generated for each case reducer function
export const { setLoading,setCart } = cartSlice.actions;

export default cartSlice.reducer;
