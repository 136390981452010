import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: [],
  isLoading: false,

};

export const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setLoading } = customerSlice.actions;

export default customerSlice.reducer;
