import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./Reducers/ProductReducer";
import AuthReducer from "./Reducers/AuthReducer";
import Categoryreducer from "./Reducers/CategoryReducer";
import OrderReducer from "./Reducers/orderReducer";
import CustomerReducer from "./Reducers/CustomerReducer";
import CartReducer from "./Reducers/cartReducer";
import WishlistReducer from "./Reducers/WishlistReducer";

export const store = configureStore({
  reducer: {
    productReducer,
    AuthReducer,
    Categoryreducer,
    OrderReducer,
    CustomerReducer,
    CartReducer,
    WishlistReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
