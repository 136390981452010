import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoryAction } from "store/Actions/CategoryAction";
import { fetchCustomerbyToken } from "store/Actions/CustomerAction";
import {
  fetchBestSellerProducts,
  fetchNewArrivalProducts,
  fetchProductsAction,
  fetchTrendingProducts,
} from "store/Actions/ProductAction";
import {
  addItemToWishlist,
  deleteWishlistItem,
  getWishlist,
} from "store/Actions/WishlistAction";
import { getCart } from "store/Actions/cartAction";

function useWishlist() {
  const dispatch = useDispatch<any>();

  const wishlistItems = useSelector(
    (state: any) => state.WishlistReducer.wishlistItems
  );

  const handleWishlist = (productId: any, isLiked: any) => {
    
    if (isLiked) {
      dispatch(
        deleteWishlistItem({
          id: isLiked?._id,
        })
      );
    } else {
      dispatch(
        addItemToWishlist({
          product: productId,
        })
      );
    }
  };

  return {
    wishlistItems,
    handleWishlist,
  };
}

export default useWishlist;
