// @ts-nocheck
import React, { FC, useEffect, useState } from "react";
import imageRightPng from "images/hero-right.png";
import imageRightPng2 from "images/hero-right-2.png";
import imageRightPng3 from "images/hero-right-3.png";

import backgroundLineSvg from "images/Moon.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import axios from "axios";
import { BASE_URL } from "utils/codes";
import { getHeaders } from "utils/getHeaders";
import { setLoading } from "store/Reducers/AuthReducer";
import { useDispatch } from "react-redux";

interface Hero2DataType {
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
}
export interface SectionHero2Props {
  className?: string;
}

let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero2: FC<SectionHero2Props> = ({ className = "" }) => {
  // =================
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);
  const [sliders, setSliders] = useState<Hero2DataType[]>([]);

  const dispatch = useDispatch();

  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
  );
  

  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= sliders.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= sliders.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return sliders.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };

  const handleGetSliders = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/v1/slider-banners`,
        getHeaders()
      );
      dispatch(setLoading(false));
      setSliders(res.data?.data?.data);
      return res;
    } catch (error: any) {
      console.log(error);

      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  };

  useEffect(() => {
    handleGetSliders();
  }, []);

  // =================

  const renderItem = (index: number) => {
    const isActive = indexActive === index;
    const item = sliders[index];
    if (!isActive) {
      return null;
    }
    return (
      <div
        className={`nc-SectionHero2Item h-[32rem] lg:h-[37rem] nc-SectionHero2Item--animation flex lg:flex-col relative overflow-hidden ${className}`}
        key={index}
      >
        <div className="absolute bottom-2  left-1/2 -translate-x-1/2 z-20 flex justify-center">
          {sliders?.map((_, index) => {
            const isActive = indexActive === index;
            return (
              <div
                key={index}
                onClick={() => {
                  setIndexActive(index);
                  handleAfterClick();
                }}
                className={`relative px-1 py-1.5 cursor-pointer `}
              >
                <div
                  className={`relative w-20 h-1 shadow-sm rounded-md bg-white `}
                >
                  {isActive && (
                    <div
                      className={`nc-SectionHero2Item__dot absolute inset-0 bg-slate-900 rounded-md ${
                        isActive ? " " : " "
                      }`}
                    ></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <Prev
          className="hidden md:block lg:block absolute left-1 sm:left-5 -bottom-2 sm:top-1/2 sm:-translate-y-1/2 z-10 lg:top-2/4 lg:bottom-auto !text-slate-700 lg:!text-white"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickPrev={handleClickPrev}
        />
        <Next
          className="hidden md:block lg:block absolute right-1 sm:right-5 -bottom-2 sm:top-1/2 sm:-translate-y-1/2 z-10 lg:top-2/4 lg:bottom-auto !text-slate-700 lg:!text-white"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickNext={handleClickNext}
        />

        {/* <div className="absolute inset-0 bg-[#E3FFE6] h-full">
          <img
            className="absolute w-full h-full object-contain"
            src={backgroundLineSvg}
            alt="hero"
          />
        </div> */}

        <div className="">
          <div className="relative h-full ">
            <div className="mt-0 hidden lg:block lg:absolute top-0 w-full max-w-full">
              <img
                className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
                src={item.image}
                alt={item.heading}
              />
            </div>

            <div className="mt-0 block lg:hidden w-full max-w-full">
              <img
                className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
                src={item.imageMobile}
                alt={item.heading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!sliders.length) {
    return null;
  }

  return <>{sliders?.map((_, index) => renderItem(index))}</>;
};

export default SectionHero2;
