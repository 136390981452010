import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCashFree, fetchRazorPay, verifyCashFree } from "store/Actions/cartAction";
import { useLocation, useNavigate } from "react-router-dom";

function usePayment({ orderTotal }: any) {
  const dispatch = useDispatch<any>();
  const search = useLocation().search;
  const navigate = useNavigate()

  const user = useSelector((state: any) => state.CustomerReducer.user);

  const handleConfirmOrder = (order_amount: any) => {
    dispatch(
      fetchRazorPay({
        order_amount: order_amount,
        order_currency: "INR",
        customer_details: {
          customer_id: user?._id,
          customer_name: user?.name,
          customer_email: user?.email,
          customer_phone: user?.phone,
          razorpayCustomerId:user?.razorpayCustomerId
        },
        orderTotal,
        navigate
      })
    );
  };

  // useEffect(() => {
  //   const order_id = new URLSearchParams(search).get("order_id");
  //   if (order_id) {
  //     dispatch(verifyCashFree({ order_id,navigate }));
  //   }
  // }, []);

  return {
    handleConfirmOrder,
  };
}

export default usePayment;
