import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import { Link } from "react-router-dom";
import AddressModal from "./AddressModal";
import useAddress from "talons/useAddress";
import EditAddressModal from "./EditAddress";

const AccountShipping = () => {
  const { customerAddress, deleteAddress } = useAddress();
  const [editAddress, setEditAddress] = useState(null);

  const [addresses, setAddresses] = useState([
    { id: 1, label: "Home", address: "123 Main St, City, Country" },
    { id: 2, label: "Work", address: "456 Office St, Town, Country" },
    { id: 3, label: "Work", address: "456 Office St, Town, Country" },

    { id: 4, label: "Work", address: "456 Office St, Town, Country" },

    { id: 5, label: "Work", address: "456 Office St, Town, Country" },

    // Add more addresses as needed
  ]);

  const [selectedAddress, setSelectedAddress] = useState("");

  const [addressModal, setAddressModal] = useState(false);

  const handleAddAddress = () => {
    setAddressModal(true);
  };

  const handleSelectAddress = (id: any) => {
    setSelectedAddress(id);
  };

  const handleEditAddressPoup = (data: any) => {
    setEditAddress(data);
  };

  return (
    <div>
      <CommonLayout>
        <h2 className="text-2xl font-semibold mb-4">Select Address</h2>
        <div className=" p-4 bg-white rounded ">
          {/* Address List */}
          <div className="mb-4">
            <ul>
              {customerAddress?.map((address: any, index: any) => (
                <li
                  key={index}
                  className={`cursor-pointer py-4 px-5 flex justify-between ${
                    address.default
                      ? "bg-gray-200"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => handleSelectAddress(address._id)}
                >
                  <div className="w-[60%]">
                    {`${address.flat} `}
                    {`${address.landmark} `}
                    {`${address.street} `}
                    {`${address.city} `}
                    {`${address.zipCode} `}
                    {`${address.state} `}
                    {`${address.country} `}
                  </div>
                  <div className="flex gap-2 ">
                    <span onClick={() => handleEditAddressPoup(address)}>
                      Edit
                    </span>
                    <span onClick={() => deleteAddress(address._id)}>
                      Delete
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Add Address Form */}
          <div className="flex justify-end">
            {/* Implement your form to add a new address */}
            <button
              onClick={handleAddAddress}
              className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-90 bg-[#f28c49] dark:bg-slate-100 hover:bg-[#df7a36] text-slate-50 dark:text-slate-800 shadow-xl sm:!px-7 shadow-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"
            >
              Add Address
            </button>
          </div>
        </div>
      </CommonLayout>
      {addressModal && (
        <AddressModal
          show={addressModal}
          onCloseModalView={() => setAddressModal(false)}
        />
      )}

      {editAddress && (
        <EditAddressModal
          show={editAddress ? true : false}
          onCloseModalView={() => setEditAddress(null)}
          data={editAddress}
        />
      )}
    </div>
  );
};

export default AccountShipping;
