import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isError: false,
  error: {},
  isLoading: false,
  customerAddress: []
};

export const AuthReducer = createSlice({
  name: "AuthReducer",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoginResponse: (state, action) => {
      state.error = {};
      state.isError = false;
    },
    setLoginError: (state, action) => {
      state.error = action.payload;
      state.isError = true;
    },
    setCustomerAddress: (state, action) => {
      state.customerAddress = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoginResponse, setLoading, setLoginError, setCustomerAddress } =
  AuthReducer.actions;

export default AuthReducer.reducer;
