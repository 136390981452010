// @ts-nocheck
/* eslint-disable */
import React from "react";
import { NavLink } from "react-router-dom";

const ProductSection = ({ categories }) => {
  return (
    <div className="container flex flex-col gap-5 justify-around py-8 lg:flex-row lg:justify-center ">
      {categories.map((category, index) => (
        <div
          key={index}
          className="bg-white shadow-lg rounded-lg text-center p-8 flex flex-col gap-8 lg:flex-1"
        >
          <div className="mx-auto">
            <span className="flex justify-center items-center font-bold text-2xl">
              {category?.name}
            </span>
          </div>
          <div className="mt-4">
            <img
              src={category?.image}
              alt={category?.name}
              className="w-full object-contain mx-auto "
            />
          </div>
          <div className="mt-4">
            <NavLink
              href={category?.shopUrl}
              className="bg-[#f28c49] hover:bg-[#df7a36] text-white py-3 px-6 rounded-full text-sm "
              to={`page-collection-2/${category._id}`}
            >
              Shop Now
            </NavLink>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductSection;
