import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    wishlistItems: []
};

export const WishlistSlice = createSlice({
    name: "cartSlice",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setwishlistItems: (state, action) => {
            state.wishlistItems = action.payload;
        },

    },
});

// Action creators are generated for each case reducer function
export const { setLoading, setwishlistItems } = WishlistSlice.actions;

export default WishlistSlice.reducer;
