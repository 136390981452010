// @ts-nocheck
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const CategoryDropdown = ({ categoryItems: categories }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative text-left flex items-center">
      {/* Dropdown button */}
      <div>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex justify-between cursor-pointer rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[#f28c49] hover:bg-[#df7a36]  text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-red-500 outline-none"
        >
          All categories
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.292 7.707a1 1 0 011.414 0L10 11.001l3.293-3.294a1 1 0 011.414 1.415l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>

      {/* Dropdown panel */}
      {isOpen && (
        <div className="origin-top-left absolute -left-10 top-10 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1 max-h-64 overflow-y-auto">
            {categories.map((category, index) => (
              <NavLink
                key={category?._id}
                href="#"
                className={`block px-4 py-2 text-sm ${
                  category === "All categories" ? "font-bold" : "text-gray-700"
                } hover:bg-gray-100`}
                to={category?.href}
                onClick={() => {
                    setIsOpen(false)
                }}
              >
                {category?.name}
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryDropdown;
