import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyCashFree } from "store/Actions/cartAction";

const VerifyPayment = () => {
  const search = useLocation().search;
  const dispatch = useDispatch<any>();

  useEffect(() => {
    const order_id = new URLSearchParams(search).get("order_id");
    
    dispatch(verifyCashFree({ id: order_id }));
  }, []);

  return null;
};

export default VerifyPayment;
