export const BASE_URL = "http://localhost:3000";

export const getHeaders = () => {
    const token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return config;
};
