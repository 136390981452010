import axios from "axios";
import { toast } from "react-toastify";
import { getHeaders } from "utils/getHeaders";
import { BASE_URL } from "utils/codes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUser, setLoading } from "../Reducers/CustomerReducer";


export const fetchCustomerbyToken = createAsyncThunk(
  "fetchCustomerbyToken",
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(`${BASE_URL}/api/v1/profile`, getHeaders());
      dispatch(setLoading(false));
      dispatch(setUser(res?.data));
      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      // toast.error(error.response.data);
    }
  }
);


export const updateProfile = createAsyncThunk(
  "updateProfile",
  async ({ ...data }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.patch(
        `${BASE_URL}/api/v1/profile`,
        {
          ...data,
        },
        getHeaders()
      );
      dispatch(setLoading(false));
      dispatch(fetchCustomerbyToken({}));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data.message);
    }
  }
);
