import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";

export interface FullPageLoaderProps {}
const FullPageloader: React.FC<FullPageLoaderProps> = ({}) => {
  const categoryLoading = useSelector(
    (state: any) => state.Categoryreducer.isLoading
  );

  const productReducerLoading = useSelector(
    (state: any) => state.productReducer.isLoading
  );

  const AuthReducerLoading = useSelector(
    (state: any) => state.AuthReducer.isLoading
  );

  const CartReducerLoading = useSelector(
    (state: any) => state.CartReducer.isLoading
  );

  const WishlistLoading = useSelector(
    (state: any) => state.WishlistReducer.isLoading
  );

  const CustomerLoading = useSelector(
    (state: any) => state.CustomerReducer.isLoading
  );

  const OrderLoading = useSelector(
    (state: any) => state.OrderReducer.isLoading
  );

  const isLoading =
    categoryLoading ||
    productReducerLoading ||
    AuthReducerLoading ||
    CartReducerLoading ||
    WishlistLoading ||
    CustomerLoading ||
    OrderLoading;

  if (!isLoading) {
    return null;
  }

  return (
    <>
      <div className="flex opacity-[0.6] z-[100] fixed left-0 top-0 right-0 bottom-0  items-center justify-center min-h-screen p-5 bg-gray-100 min-w-screen">
        <div className="flex space-x-2 animate-pulse">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      </div>
    </>
  );
};

export default FullPageloader;
