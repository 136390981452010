import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchProductById,
  fetchReviewByProductId,
} from "store/Actions/ProductAction";

function useProductDetails() {
  const { id } = useParams();

  const productDetails = useSelector(
    (state: any) => state.productReducer.productDetails
  );


  const productReview = useSelector(
    (state: any) => state.productReducer.productReview
  );


  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(fetchProductById({ id }));
    dispatch(fetchReviewByProductId({ id }));
  }, [id]);

  return {
    productDetails,
    productReview,
    productId:id
  };
}

export default useProductDetails;
