//@ts-nocheck
import { Dialog, Transition } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/24/solid";
import Label from "components/Label/Label";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Textarea from "shared/Textarea/Textarea";
import { useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
  GetCountries,
  GetState,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { createCustomerAddress } from "store/Actions/AuthAction";
import Checkbox from "shared/Checkbox/Checkbox";

export interface AddressModalProps {
  show: boolean;
  onCloseModalView: () => void;
}

interface FormInputs {
  flat: string;
  street?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  state?: string;
  landmark?: string;
}

const AddressModal: FC<AddressModalProps> = ({ show, onCloseModalView }) => {
  const [currentRating, setCurrentRating] = useState(1);
  const [countries, setCountries] = useState([]);
  const [stateList, setStateList] = useState([]);

  const dispatch = useDispatch<any>();

  const form = useForm<FormInputs>({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  const onSubmit = (data: any) => {
    dispatch(
      createCustomerAddress({
        close: onCloseModalView,
        ...data,
      })
    );
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountries(result);
      setValue("country", result[0].name);

      GetState(parseInt(result[0].id)).then((result) => {
        setStateList(result);
        setValue("state", result[0].name);
      });
    });
  }, []);

  const handleCountryChange = (e) => {
    const country = countries.find((item) => item.name === e.target.value);

    GetState(parseInt(country.id)).then((result) => {
      setStateList(result);
      setValue("state",result[0].name)
    });
  };

  const handleStateChange = (e) => {
    setValue("state", e.target.value);
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onCloseModalView}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full max-w-5xl">
              <div className="inline-flex pb-5 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="headlessui-dialog-title-70"
                  >
                    Create Address
                  </h3>
                  <span className="absolute right-3 top-3">
                    <ButtonClose onClick={onCloseModalView} />
                  </span>
                </div>
                <div className="py-8 px-4 flex justify-center">
                  <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        <Label>flat</Label>
                        <Input
                          {...register("flat", {
                            required: "flat Required",
                          })}
                          className="mt-1.5"
                        />
                        <span className="text-red-600">
                          {errors?.flat ? errors?.flat?.message : null}
                        </span>
                      </div>
                      <div>
                        <Label>landmark</Label>
                        <Input
                          {...register("landmark", {
                            required: "landmark Required",
                          })}
                          className="mt-1.5"
                        />
                        <span className="text-red-600">
                          {errors?.landmark ? errors?.landmark?.message : null}
                        </span>
                      </div>

                      <div>
                        <Label>street</Label>
                        <Input
                          className="mt-1.5"
                          {...register("street", {
                            required: "street Required",
                          })}
                        />
                        <span className="text-red-600">
                          {errors?.street ? errors?.street?.message : null}
                        </span>
                      </div>

                      <div>
                        <Label>city</Label>
                        <Input
                          className="mt-1.5"
                          {...register("city", {
                            required: "city Required",
                          })}
                        />
                        <span className="text-red-600">
                          {errors?.city ? errors?.city?.message : null}
                        </span>
                      </div>

                      <div>
                        <Label>zipCode</Label>
                        <Input
                          className="mt-1.5"
                          {...register("zipCode", {
                            required: "zipCode Required",
                          })}
                        />
                        <span className="text-red-600">
                          {errors?.zipCode ? errors?.zipCode?.message : null}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <div className="w-[45%]">
                          <Label>Country</Label>
                          <select
                            className="mt-1.5 nc-Select block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                            {...register("country", {
                              required: "Country Required",
                              onChange: handleCountryChange,
                            })}
                          >
                            {countries?.map((item: any) => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="text-red-600">
                            {errors?.country ? errors?.country?.message : null}
                          </span>
                        </div>
                        <div className="w-[45%]">
                          <Label>State</Label>
                          <select
                            className="mt-1.5 nc-Select block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                            {...register("state", {
                              required: "State Required",
                            })}
                          >
                            {stateList?.map((item: any) => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="text-red-600">
                            {errors?.state ? errors?.state?.message : null}
                          </span>
                        </div>
                      </div>
                      <div className="flex mt-4 items-center gap-4">
                        <Label>Default Shipping</Label>

                        <input
                          type="checkbox"
                          className={`focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 `}
                          {...register("default", {
                            required: false,
                          })}
                        />
                      </div>

                      <div className="pt-2 flex justify-end">
                        <ButtonPrimary type="submit"> Submit</ButtonPrimary>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddressModal;
