import axios from "axios";
import { toast } from "react-toastify";
import { getHeaders } from "utils/getHeaders";
import { BASE_URL } from "utils/codes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setProducts, setLoading, setProductDetails, setNewArrivalProducts, setTrendingProducts, setBestSellerProducts, setProductReview } from "../Reducers/ProductReducer";


export const fetchProductsAction = createAsyncThunk(
  "fetchProductsAction",
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(`${BASE_URL}/api/v1/products`, getHeaders());
      dispatch(setLoading(false));
      dispatch(setProducts(res?.data?.data?.data));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);


export const fetchNewArrivalProducts = createAsyncThunk(
  "fetchNewArrivalProducts",
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(`${BASE_URL}/api/v1/products-newarrivals/`, getHeaders());
      dispatch(setLoading(false));
      dispatch(setNewArrivalProducts(res?.data?.data?.data));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);


export const fetchTrendingProducts = createAsyncThunk(
  "fetchTrendingProducts",
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(`${BASE_URL}/api/v1/products-trending`, getHeaders());
      dispatch(setLoading(false));
      dispatch(setTrendingProducts(res?.data?.data?.data));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);


export const fetchBestSellerProducts = createAsyncThunk(
  "fetchBestSellerProducts",
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(`${BASE_URL}/api/v1/products-bestseller`, getHeaders());
      dispatch(setLoading(false));
      dispatch(setBestSellerProducts(res?.data?.data?.data));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);



export const fetchProcutsByCategory = createAsyncThunk(
  "fetchProcutsByCategory",
  async ({ id, limit, page, isNew = false }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(
        `${BASE_URL}/api/v1/products/category/${id}/?page=${page}&&limit=${limit}`,
        getHeaders()
      );
      dispatch(setLoading(false));
      res.data.data.isNew = isNew
      dispatch(setProducts(res?.data?.data));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);

export const fetchSearchProducts = createAsyncThunk(
  "fetchSearchProducts",
  async ({ search }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(
        `${BASE_URL}/api/v1/products/?name=${search}`,
        getHeaders()
      );
      dispatch(setLoading(false));
      dispatch(setProducts(res?.data?.data?.data));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);




export const fetchProductById = createAsyncThunk(
  "fetchProcutById",
  async ({ id }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(
        `${BASE_URL}/api/v1/products/${id}`,
        getHeaders()
      );
      dispatch(setLoading(false));
      dispatch(setProductDetails(res?.data?.data?.doc));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);


// rating

export const fetchReviewByProductId = createAsyncThunk(
  "fetchReviewByProductId",
  async ({ id }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(
        `${BASE_URL}/api/v1/reviews/${id}`,
        getHeaders()
      );
      dispatch(setLoading(false));
      dispatch(setProductReview(res?.data?.data.data));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);


// create review

export const createReview = createAsyncThunk(
  "createReview",
  async ({ close, ...data }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.post(
        `${BASE_URL}/api/v1/reviews/`,
        {
          ...data
        },
        getHeaders()
      );

      close()
      dispatch(setLoading(false));
      dispatch(fetchReviewByProductId({ id: data.productId }));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);


// delete review

export const deleteReview = createAsyncThunk(
  "deleteReview",
  async ({ id, productId }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.delete(
        `${BASE_URL}/api/v1/reviews/${id}`,
        getHeaders()
      );

      dispatch(setLoading(false));
      dispatch(fetchReviewByProductId({ id: productId }));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);