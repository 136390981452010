import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/codes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setCustomerAddress,
  setLoading,
  setLoginError,
  setLoginResponse,
} from "../Reducers/AuthReducer";
import { getHeaders } from "utils/getHeaders";
import { fetchCustomerbyToken } from "./CustomerAction";
import { getWishlist } from "./WishlistAction";
import { getCart } from "./cartAction";

export const generateToken = createAsyncThunk(
  "generateToken",
  async (data: any, { dispatch }) => {
    const { email, password, navigate } = data;

    try {
      dispatch(setLoading(true));
      const res = await axios.post(`${BASE_URL}/api/v1/users/admin-login`, {
        email,
        password,
      });

      if (res.data.status == "success") {
        dispatch(setLoginResponse(res.data));
        localStorage.setItem("token", res.data.token);
        dispatch(fetchCustomerbyToken({}))
        dispatch(getWishlist({}))
        dispatch(getCart({}))
        navigate("/");
      }
      dispatch(setLoading(false));
      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setLoginError(error.response.data));
      toast.error(error.response.data.message);
    }
  }
);

export const createAccountAction = createAsyncThunk(
  "createAccountAction",
  async ({ navigate, ...data }: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const res = await axios.post(`${BASE_URL}/api/v1/users/signup`, {
        ...data,
      });

      if (res.data.status == "success") {
        dispatch(setLoginResponse(res.data));
        toast.success("Account Created SuccesFully");
        localStorage.setItem("token", res.data.token);
        navigate("/login");
      }
      dispatch(setLoading(false));
      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setLoginError(error.response.data));
      toast.error(error.response.data.message);
    }
  }
);

export const forgetPasswordAction = createAsyncThunk(
  "forgetPasswordAction",
  async ({ navigate, ...data }: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const res = await axios.post(`${BASE_URL}/api/v1/users/forgot-password`, {
        ...data,
      });

      if (res.data.status == "success") {
        toast.success(res.data.message);
      }

      dispatch(setLoading(false));
      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setLoginError(error.response.data));
      toast.error(error.response.data.message);
    }
  }
);


export const resetPasswordToken = createAsyncThunk(
  "resetPasswordToken",
  async ({ token, ...data }: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const res = await axios.post(`${BASE_URL}/api/v1/users/reset-password/${token}`, {
        ...data,
      });

      if (res.data.status == "success") {
        toast.success(res.data.message);
      }

      dispatch(setLoading(false));
      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setLoginError(error.response.data));
      toast.error(error.response.data.message);
    }
  }
);


export const updateCustomerPassword = createAsyncThunk(
  "updateCustomerPassword",
  async ({ reset, ...data }: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const res = await axios.post(`${BASE_URL}/api/v1/change-password`, {
        ...data,
      },
        getHeaders()
      );

      reset()
      toast.success(res.data.message)
      dispatch(setLoading(false));
      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setLoginError(error.response.data));
      toast.error(error.response.data.message);
    }
  }
);


export const getCustomerAddress = createAsyncThunk(
  "getCustomerAddress",
  async ({ }: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const res = await axios.get(`${BASE_URL}/api/v1/address`, getHeaders()
      );

      dispatch(setCustomerAddress(res.data.data.data));

      dispatch(setLoading(false));
      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data.message);
    }
  }
);




export const createCustomerAddress = createAsyncThunk(
  "createCustomerAddress",
  async ({ close, ...data }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.post(
        `${BASE_URL}/api/v1/address/`,
        {
          ...data
        },
        getHeaders()
      );

      if (typeof close == "function") {
        close()
      }
      dispatch(setLoading(false));
      dispatch(getCustomerAddress({}));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);



export const updateCustomerAddress = createAsyncThunk(
  "updateCustomerAddress",
  async ({ id, close, ...data }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.patch(
        `${BASE_URL}/api/v1/address/${id}`,
        {
          ...data
        },
        getHeaders()
      );

      if (typeof close == "function") {
        close()
      }
      dispatch(setLoading(false));
      dispatch(getCustomerAddress({}));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);


export const deleteCustomerAddress = createAsyncThunk(
  "deleteCustomerAddress",
  async ({ id }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.delete(
        `${BASE_URL}/api/v1/address/${id}`,
        getHeaders()
      );


      dispatch(setLoading(false));
      dispatch(getCustomerAddress({}));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);

