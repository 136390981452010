import Prices from "components/Prices";
import { PRODUCTS } from "data/data";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchOrdersAction, ReturnOrder } from "store/Actions/OrderAction";
import { Link } from "react-router-dom";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BagIcon from "components/BagIcon";

const AccountOrder = () => {
  const dispatch = useDispatch<any>();

  const orders = useSelector((state: any) => state.OrderReducer.orders);

  useEffect(() => {
    dispatch(fetchOrdersAction({}));
  }, []);

  const handlereturnOrder = (orderId: string) => {
    dispatch(
      ReturnOrder({
        orderId,
      })
    );
  };

  const renderProductItem = (item: any, index: number) => {
    const {
      price,
      quantity,
      product: { images, name },
    } = item;
    return (
      <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={images?.length && images[0]}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <Link
                  to={`/product-detail/${item.product._id}`}
                  className="text-base font-medium line-clamp-1"
                >
                  {name}
                </Link>
                {/* <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>{"Natural"}</span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{"XL"}</span>
                </p> */}
              </div>
              <Prices className="mt-0.5 ml-2" price={price} />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400 flex items-center">
              <span className="hidden sm:inline-block">Qty</span>
              <span className="inline-block sm:hidden">x</span>
              <span className="ml-2">{quantity}</span>
            </p>

            {/* <div className="flex">
              <button
                type="button"
                className="font-medium text-indigo-600 dark:text-primary-500 "
              >
                Leave review
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = (order: any, index: any) => {
    const date = new Date(order.createdAt).toDateString();

    return (
      <div
        className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0"
        key={index}
      >
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div>
            <p className="text-lg font-semibold">#{order?.orderId}</p>
            <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
              <span>{date}</span>
              <span className="mx-2">·</span>
              <span className="text-primary-500">{order?.status}</span>
            </p>
          </div>
          <div className="mt-3 sm:mt-0 flex items-cente gap-2 justify-center">
            <span>Total Amount</span>
            <Prices className="mt-0.5 ml-2" price={order?.totalAmount} />
            {
              order?.isReturnOrder ? (
                <>
                   <span className="text-primary-500 flex items-center">Order applied for Return</span>
                </>
              ) : (
                <div
              className="border-spacing-1 text-primary-500 cursor-pointer flex items-center"
              onClick={() => handlereturnOrder(order?._id)}
            >
              Return Order
            </div>
              )
            }
          </div>
          {/* <div className="mt-3 sm:mt-0">
            <ButtonSecondary
              sizeClass="py-2.5 px-4 sm:px-6"
              fontSize="text-sm font-medium"
            >
              View Order
            </ButtonSecondary>
          </div> */}
        </div>
        <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
          {order?.items?.map(renderProductItem)}
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2>
          {orders?.slice().reverse().map(renderOrder)}
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrder;
