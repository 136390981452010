import { Dialog, Transition } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/24/solid";
import Label from "components/Label/Label";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Textarea from "shared/Textarea/Textarea";
import { useForm } from "react-hook-form";
import { createReview } from "store/Actions/ProductAction";

export interface CreateReviewModalProps {
  show: boolean;
  onCloseModalViewAllReviews: () => void;
  productId?: string;
}

interface FormInputs {
  review: string;
  rating?: String | Number;
}

const CreateReviewModal: FC<CreateReviewModalProps> = ({
  show,
  onCloseModalViewAllReviews,
  productId,
}) => {
  const [currentRating, setCurrentRating] = useState(1);

  const dispatch = useDispatch<any>();

  const form = useForm<FormInputs>({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  useEffect(() => {
    setValue("rating", currentRating);
  }, [currentRating]);

  const onSubmit = (data: any) => {
    dispatch(
      createReview({
        ...data,
        productId,
        close: onCloseModalViewAllReviews,
      })
    );
  };

  const renderRatingStar = () => {
    return (
      <div className="flex gap-4">
        {(Array as any).from({ length: 5 }).map((i: any, index: any) => {
          const isRated = currentRating >= index + 1;
          let ratingJsx;

          if (isRated) {
            ratingJsx = (
              <div className={`w-5 h-5 cursor-pointer text-yellow-500`}>
                <StarIcon key={index} />
              </div>
            );
          } else {
            ratingJsx = (
              <div className={`w-5 h-5 cursor-pointer`} key={index}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
              </div>
            );
          }

          const element = React.createElement(
            "div",
            { onClick: () => setCurrentRating(index + 1), key: index },
            ratingJsx
          );
          return element;
        })}
      </div>
    );
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onCloseModalViewAllReviews}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 h-screen w-full max-w-5xl">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="headlessui-dialog-title-70"
                  >
                    Create Review
                  </h3>
                  <span className="absolute right-3 top-3">
                    <ButtonClose onClick={onCloseModalViewAllReviews} />
                  </span>
                </div>
                <div className="py-8 px-5 flex justify-center">
                  <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        <Label>Enter your Review</Label>
                        <Textarea
                          className="mt-1.5"
                          {...register("review", {
                            required: "review is required",
                          })}
                        />
                        <p className="text-red-500">{errors.review?.message}</p>
                      </div>

                      {renderRatingStar()}

                      <div className="pt-2">
                        <ButtonPrimary type="submit">
                          Create Review
                        </ButtonPrimary>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateReviewModal;
