import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateCustomerPassword } from "store/Actions/AuthAction";

interface FormInputs {
  newPassword: string;
  currentPassword?: string;
  confirmPassword?: string;
}

const AccountPass = () => {
  const form = useForm<FormInputs>({});
  const dispatch = useDispatch<any>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  const onSubmit = (data: FormInputs) => {
    if (data.newPassword !== data.confirmPassword) {
      return toast.error("Confirm password and New password not match");
    }

    dispatch(
      updateCustomerPassword({
        reset,
        ...data,
      })
    );
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Update your password
          </h2>
          <form
            className=" max-w-xl space-y-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Label>Current password</Label>
              <Input
                type="password"
                className="mt-1.5"
                {...register("currentPassword", {
                  required: "Current Password Required",
                })}
              />
              <span className="text-red-600">
                {errors?.currentPassword
                  ? errors?.currentPassword?.message
                  : null}
              </span>
            </div>
            <div>
              <Label>New password</Label>
              <Input
                {...register("newPassword", {
                  required: "New Password Required",
                })}
                type="password"
                className="mt-1.5"
              />
              <span className="text-red-600">
                {errors?.newPassword ? errors?.newPassword?.message : null}
              </span>
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input
                {...register("confirmPassword", {
                  required: "Confirm password Required",
                })}
                type="password"
                className="mt-1.5"
              />
              <span className="text-red-600">
                {errors?.confirmPassword
                  ? errors?.confirmPassword?.message
                  : null}
              </span>
            </div>
            <div className="pt-2">
              <ButtonPrimary>Update password</ButtonPrimary>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
