import React from "react";
import { useSelector } from "react-redux";

function useNavlist() {
  const categoryItems = useSelector(
    (state: any) => state.Categoryreducer.categoryItems
  );

  const categoryFormatedItems = () => {
    return categoryItems.filter((item:any) => !item?.isDynamicCategory).map((item: any) => {
      return {
        id: item._id,
        href: `page-collection-2/${item._id}`,
        name: item.name,
      };
    })
  };

  const dynamicCategory = categoryItems.filter((i: any) => i.isDynamicCategory);


  return {
    categoryItems: categoryFormatedItems(),
    dynamicCategory
  };
}

export default useNavlist;
