import NotifyAddTocart from "components/NotifyAddTocart";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToCart,
  applyCouponCode,
  cancelCouponCode,
  updateCartItem,
} from "store/Actions/cartAction";
import detail1JPG from "images/2.jpg";
import detail2JPG from "images/3.jpg";
import detail3JPG from "images/4.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { getRemoveCartInput, getUpdateCartInput } from "utils/helper";

function useCart({ quantity, product, reset }: any) {
  const dispatch = useDispatch<any>();
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  const params = useParams();

  const cart = useSelector((state: any) => state.CartReducer.cart);

  const shippingSubTotal = cart?.items?.reduce((acc: any, product: any) => {
    return acc + product.price * product.quantity;
  }, 0);

  const shippingEstimate = 0;
  const taxEstimate = 0;
  const total =
    shippingSubTotal + shippingEstimate + taxEstimate - cart?.discount;

  const navigate = useNavigate()

  const notifyAddTocart = (
    name: string,
    quantity: any,
    price: any,
    image: any
  ) => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={!image ? LIST_IMAGES_DEMO[0] : image}
          qualitySelected={quantity}
          show={true}
          price={price}
          name={name}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 2000 }
    );
  };

  const handleAddToCart = () => {
    const token = localStorage.getItem("token");
    if(!token){
      toast('Please Signin for add item to cart')
      navigate("/login")
    }

    const input = {
      productId: params.id,
      quantity,
      price: product?.amount,
    };

    dispatch(
      addItemToCart({
        ...input,
        notifyAddTocart,
        name: product?.name,
        image: product && product?.images?.length ? product.images[0] : null,
      })
    );
  };

  const handleUpdateItemQuantity = (updatedQty: number, id: string) => {
    const input = getUpdateCartInput(cart?.items, updatedQty, id);

    dispatch(
      updateCartItem({
        ...input,
      })
    );
  };

  const handleRemoveItem = (id: string) => {
    const input = getRemoveCartInput(cart?.items, id);
    dispatch(
      updateCartItem({
        ...input,
      })
    );
  };

  const handleApplyCoupon = async (code: any) => {
    const res = await dispatch(
      applyCouponCode({
        code,
        total,
        reset,
      })
    );
  };

  const handleRemoveCouponCode = (event: any, reset: any) => {
    event.preventDefault();
    dispatch(cancelCouponCode({}));
    reset();
  };

  return {
    handleAddToCart,
    cart,
    handleUpdateItemQuantity,
    handleRemoveItem,
    handleApplyCoupon,
    shippingSubTotal,
    shippingEstimate,
    taxEstimate,
    orderTotal: total,
    handleRemoveCouponCode,
  };
}

export default useCart;
