import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  categoryItems: [],
  isLoading: false,
};

export const Categoryreducer = createSlice({
  name: "Categoryreducer",
  initialState,
  reducers: {
    setCategoryItems: (state, action) => {
      state.categoryItems = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCategoryItems, setLoading } = Categoryreducer.actions;

export default Categoryreducer.reducer;
