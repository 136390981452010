import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoryAction } from "store/Actions/CategoryAction";
import {
  deleteCustomerAddress,
  getCustomerAddress,
  updateCustomerAddress,
} from "store/Actions/AuthAction";

function useAddress() {
  const dispatch = useDispatch<any>();

  const customerAddress = useSelector(
    (state: any) => state.AuthReducer.customerAddress
  );

  const deleteAddress = (id: any) => {
    dispatch(deleteCustomerAddress({ id }));
  };

  const handleChangeShippingAddress = (id: any) => {
    
    dispatch(
      updateCustomerAddress({
        id,
        default: true,
      })
    );
  };

  useEffect(() => {
    dispatch(getCustomerAddress({}));
  }, []);

  return {
    customerAddress,
    deleteAddress,
    handleChangeShippingAddress,
  };
}

export default useAddress;
