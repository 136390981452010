//@ts-nocheck

import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";
import useAddress from "talons/useAddress";
import { useForm } from "react-hook-form";
import { GetCountries, GetState } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { createCustomerAddress } from "store/Actions/AuthAction";
import { useDispatch } from "react-redux";

interface Props {
  isActive: boolean;
  onCloseActive: () => void;
  onOpenActive: () => void;
}

interface FormInputs {
  flat: string;
  street?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  state?: string;
  landmark?: string;
}

const ShippingAddress: FC<Props> = ({
  isActive,
  onCloseActive,
  onOpenActive,
}) => {
  
  const { customerAddress, handleChangeShippingAddress } = useAddress();

  const dispatch = useDispatch()

  const [countries, setCountries] = useState([]);
  const [stateList, setStateList] = useState([]);

  const form = useForm<FormInputs>({});

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = form;

  useEffect(() => {
    GetCountries().then((result) => {
      setCountries(result);
      setValue("country",result[0].name)

      GetState(parseInt(result[0].id)).then((result) => {
        setStateList(result);
        setValue("state",result[0].name)
      });
    });
  }, []);

  const handleCountryChange = (e) => {
    const country = countries.find((item) => item.name === e.target.value);

    GetState(parseInt(country.id)).then((result) => {
      setStateList(result);
      setValue("state",result[0].name)
    });
  };

  const onSubmit = (data: any) => {
    dispatch(
      createCustomerAddress({
        ...data,
        default: true,
        close: reset,
      })
    );
  };

  const renderShippingAddress = () => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase">SHIPPING ADDRESS</span>
              {/* <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg> */}
            </h3>
            {/* <div className="font-semibold mt-1 text-sm">
              <span className="">
                St. Paul's Road, Norris, SD 57560, Dakota, USA
              </span>
            </div> */}
          </div>
          {/* <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={onOpenActive}
          >
            Change
          </ButtonSecondary> */}
        </div>
        <form
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
            isActive ? "block" : "hidden"
          }`}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Flat</Label>
              <Input
                className="mt-1.5"
                {...register("flat", {
                  required: "flat Required",
                })}
              />
              <span className="text-red-600">
                {errors?.flat ? errors?.flat?.message : null}
              </span>
            </div>
            <div>
              <Label className="text-sm">Landmark</Label>
              <Input
                className="mt-1.5"
                {...register("landmark", {
                  required: "landmark Required",
                })}
              />
              <span className="text-red-600">
                {errors?.landmark ? errors?.landmark?.message : null}
              </span>
            </div>
          </div>

          {/* ============ */}
          <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
            <div>
              <Label className="text-sm">Street</Label>
              <Input
                className="mt-1.5"
                {...register("street", {
                  required: "street Required",
                })}
              />
              <span className="text-red-600">
                {errors?.street ? errors?.street?.message : null}
              </span>
            </div>
            <div>
              <Label className="text-sm">City</Label>
              <Input
                className="mt-1.5"
                {...register("city", {
                  required: "city Required",
                })}
              />
              <span className="text-red-600">
                {errors?.city ? errors?.city?.message : null}
              </span>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Zip Code</Label>
              <Input
                className="mt-1.5"
                {...register("zipCode", {
                  required: "zipCode Required",
                })}
              />
              <span className="text-red-600">
                {errors?.zipCode ? errors?.zipCode?.message : null}
              </span>
            </div>
            <div>
              <Label className="text-sm">Country</Label>
              <select
                className="mt-1.5 nc-Select block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                {...register("country", {
                  required: "Country Required",
                  onChange: handleCountryChange,
                })}
              >
                {countries?.map((item: any) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
              <span className="text-red-600">
                {errors?.country ? errors?.country?.message : null}
              </span>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">State/Province</Label>
              <select
                className="mt-1.5 nc-Select block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                {...register("state", {
                  required: "State Required",
                })}
              >
                {stateList?.map((item: any) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
              <span className="text-red-600">
                {errors?.state ? errors?.state?.message : null}
              </span>
            </div>
            {/* <div>
              <Label className="text-sm">Postal code</Label>
              <Input className="mt-1.5" defaultValue="2500 " />
            </div> */}
          </div>
          {/* ============ */}
          <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary className="sm:!px-7 shadow-none">
              Save Shipping Address
            </ButtonPrimary>
            {/* <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={onCloseActive}
            >
              Cancel
            </ButtonSecondary> */}
          </div>
          {/* ============ */}
          {/* <div>
            <Label className="text-sm">Address type</Label>
            <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
              <Radio
                label={`<span class="text-sm font-medium">Home <span class="font-light">(All Day Delivery)</span></span>`}
                id="Address-type-home"
                name="Address-type"
                defaultChecked
              />
              <Radio
                label={`<span class="text-sm font-medium">Office <span class="font-light">(Delivery <span class="font-medium">9 AM - 5 PM</span>)</span> </span>`}
                id="Address-type-office"
                name="Address-type"
              />
            </div>
          </div> */}
          <div className="mb-4">
            <ul>
              {customerAddress?.map((address: any, index: any) => (
                <li
                  key={index}
                  className={`cursor-pointer py-4 px-5 flex justify-between ${
                    address.default
                      ? "bg-gray-200"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => handleChangeShippingAddress(address._id)}
                >
                  {`${address.flat} `}
                  {`${address.landmark} `}
                  {`${address.street} `}
                  {`${address.city} `}
                  {`${address.zipCode} `}
                  {`${address.state} `}
                  {`${address.country} `}
                </li>
              ))}
            </ul>
          </div>
        </form>
      </div>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddress;
