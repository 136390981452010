// @ts-nocheck

import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  createAccountAction,
  resetPasswordToken,
} from "store/Actions/AuthAction";
import Textarea from "shared/Textarea/Textarea";

const ResetPasswordPage: FC<any> = ({ className = "" }) => {
  const [passwordEye, setPasswordEye] = useState(true);

  const form = useForm<any>({});

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = (data: any) => {
    dispatch(
      resetPasswordToken({
        ...data,
        token: params?.token,
      })
    );
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Reset Password
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* OR */}
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label className="block relative">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                New Password
              </span>
              <Input
                type={passwordEye ? "password" : "text"}
                className="mt-1"
                {...register("password", {
                  required: "password is required",
                  // pattern: {
                  //   value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,32}$/,
                  //   message:
                  //     "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one digit.",
                  // },
                })}
              />
              <div
                className="absolute top-11 right-5 text-gray-600 cursor-pointer z-5"
                onClick={() => setPasswordEye(!passwordEye)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 576 512"
                >
                  <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                </svg>{" "}
              </div>

              <p className="text-red-500">{errors.password?.message}</p>
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="text-green-600" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
