import axios from "axios";
import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCategoryItems, setLoading } from "../Reducers/CategoryReducer";
import { getHeaders } from "utils/getHeaders";
import { BASE_URL } from "utils/codes";
import { setProducts } from "store/Reducers/ProductReducer";


export const fetchCategoryAction = createAsyncThunk(
  "fetchCategoryAction",
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(
        `${BASE_URL}/api/v1/categories`,
        getHeaders()
      );
      dispatch(setLoading(false));
      dispatch(setCategoryItems(res?.data?.data?.categories));

      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);

