import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  products: [],
  pageInfo: {
    totalPages: 0,
    currentPage: 1,
    totalProducts: 0
  },
  isLoading: false,
  productDetails: {},
  newArrivalProducts: [],
  trendingProducts: [],
  bestSellerProducts: [],
  productReview: []
};

export const productSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setProducts: (state, action) => {

      const { products = [], totalPages, currentPage, totalProducts, isNew } = action.payload
      
      if (isNew) {
        state.products = products
      } else {
        state.products = [
          ...state.products,
          ...products
        ]

      }


      state.pageInfo = {
        totalPages,
        currentPage,
        totalProducts
      }

    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setProductDetails: (state, action) => {
      state.productDetails = action.payload;
    },
    setNewArrivalProducts: (state, action) => {
      state.newArrivalProducts = action.payload;
    },

    setTrendingProducts: (state, action) => {
      state.trendingProducts = action.payload;
    },

    setBestSellerProducts: (state, action) => {
      state.bestSellerProducts = action.payload;
    },
    setProductReview: (state, action) => {
      state.productReview = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProducts, setLoading, setProductDetails, setBestSellerProducts, setNewArrivalProducts, setTrendingProducts, setProductReview } = productSlice.actions;

export default productSlice.reducer;
