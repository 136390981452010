import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProcutsByCategory } from "store/Actions/ProductAction";

function useCategorylist() {
  const dispatch = useDispatch<any>();
  const categoryItems = useSelector(
    (state: any) => state.Categoryreducer.categoryItems
  );

  const [currentPage, setCurrentPage] = useState(1);

  const products = useSelector((state: any) => state.productReducer.products);
  const pageInfo = useSelector((state: any) => state.productReducer.pageInfo);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(fetchProcutsByCategory({ id, limit: 12, page:currentPage, isNew: true }));
    }
  }, [id]);

  const selectedCategory = categoryItems?.find((item: any) => item._id == id);

  const isMoreProducts = pageInfo?.totalPages != currentPage;

  const handleFetchMoreProducts = () => {
    dispatch(fetchProcutsByCategory({ id, limit: 12, page: currentPage + 1 }));
    setCurrentPage(currentPage + 1)
  };


  return {
    products,
    selectedCategory,
    pageInfo,
    isMoreProducts,
    handleFetchMoreProducts
  };
}

export default useCategorylist;
