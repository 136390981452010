import React from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import FullPageloader from "components/FullPageloader/FullPageLoader";
import useInit from "talons/useInit";

function App() {
  const talons = useInit();

  return (
    <HelmetProvider>
      <Helmet>
        <title>Ciseco || Shop - eCommerce React template</title>
        <meta
          name="description"
          content="Ciseco || Shop - eCommerce React template"
        />
         <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <style>
          {`
            .adsbygoogle {
              display: block;
            }
          `}
        </style>
      </Helmet>

      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <FullPageloader />
        <MyRouter />
        <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-4475011170632872"
           data-ad-slot="4523405881"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
      <script>
        {`(adsbygoogle = window.adsbygoogle || []).push({});`}
      </script>
      </div>
    </HelmetProvider>
  );
}

export default App;
