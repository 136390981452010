import axios from "axios";
import { toast } from "react-toastify";
import { getHeaders } from "utils/getHeaders";
import { BASE_URL } from "utils/codes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setOrders, setLoading } from "../Reducers/orderReducer";

export const fetchOrdersAction = createAsyncThunk(
  "fetchOrdersAction",
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.get(`${BASE_URL}/api/v1/orders`, getHeaders());
      dispatch(setLoading(false));
      dispatch(setOrders(res?.data?.data?.data));
      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);


export const ReturnOrder = createAsyncThunk(
  "fetchOrdersAction",
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.post(`${BASE_URL}/api/v1/orders/return/${data?.orderId}`, {
        isReturnOrder: true
      }, getHeaders());
      dispatch(fetchOrdersAction({}));
      dispatch(setLoading(false));
      return res;
    } catch (error: any) {
      dispatch(setLoading(false));
      toast.error(error.response.data);
    }
  }
);

